<template>
    <MasterLayout :main_name="main_name" :link="link">
        <div class="sidebar_right_content booking_content">
            <div id="Mechanics" class="w3-container w3-border city">
                <ul class="ui-formSlide">
                    <!-- dashboard first section -->
                    <li data-step="bk_1" class="active" v-if="bookingList.length == 0">
                        <div class="v_bk_cat">
                            <img :src="frame">
                            <div class="v_con_main">
                                You Have No Upcoming Booking
                            </div>
                            <div class="bk_btn">
                                <router-link to="/villa" class="site_bk_btn">
                                    <span>Book Villa</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <li data-step="bk_6" v-else>
                        <div class="tab_inner_content">
                            <div class="tab_inner_content_main row" v-for="card in bookingList" :key="card.id">
                                <div class="d_flex col-sm" v-if="card.type == 'EventBooking'">
                                    <div class="tab_inner_content_image">
                                        <img v-if="card.event.event_image[0]" :src="card.event.event_image[0].image" />
                                    </div>
                                    <div class="tab_inner_content_date">
                                        <span>{{ card.date_format }}</span>
                                        <div v-for="villaData in card.villa" :key="villaData">
                                            <p>{{ villaData.first_name }} {{ villaData.middle_name }} {{ villaData.last_name }} [{{ villaData.mobile }}]</p>
                                            <p v-if="card.event">{{ card.event.name }}</p>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === 'COMPLETED'">
                                                <div class="aview_bar mr-1 success">
                                                    <a>
                                                        Paid
                                                    </a>
                                                </div>
                                                <div class="aview_bar">
                                                    <a :href="'http://chart.apis.google.com/chart?cht=qr&chs=250x250&chld=H|0&chl=' + villaData.user.id + '-9909334868-' + villaData.user.user_type + '&choe=UTF-8'"
                                                        data-lightbox="models" data-title="STRUCTURE-2">
                                                        My QRcode
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '2'">
                                                <div class="aview_bar mr-1 warning">
                                                    <a v-if="card.order" :href="card.order.pay_link_web">
                                                        Pay Now
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '3'">
                                                <div class="aview_bar mr-1 danger">
                                                    <a>
                                                        Cancelled
                                                    </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="d_flex col-sm" v-else-if="card.type == 'OneDayPicnic'">
                                    <div class="tab_inner_content_image">
                                        <img  src="../../../public/images/inner_page/about_us_01.jpg" />
                                    </div>
                                    <div class="tab_inner_content_date">
                                        <span>{{ card.date_format }}</span>
                                        <div v-for="villaData in card.villa" :key="villaData">
                                            <p>{{ villaData.first_name }} {{ villaData.middle_name }} {{ villaData.last_name }} [{{ villaData.mobile }}]</p>
                                            <p>One Day Picnic</p>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === 'COMPLETED'">
                                                <div class="aview_bar mr-1 success">
                                                    <a>
                                                        Paid
                                                    </a>
                                                </div>
                                                <div class="aview_bar">
                                                    <a :href="'http://chart.apis.google.com/chart?cht=qr&chs=250x250&chld=H|0&chl=' + villaData.user.id + '-' + villaData.user.mobile + '-' + villaData.user.user_type + '&choe=UTF-8'"
                                                        data-lightbox="models" data-title="STRUCTURE-2">
                                                        My QRcode
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '2'">
                                                <div class="aview_bar mr-1 warning">
                                                    <a v-if="card.order" :href="card.order.pay_link_web">
                                                        Pay Now
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '3'">
                                                <div class="aview_bar mr-1 danger">
                                                    <a>
                                                        Cancelled
                                                    </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="d_flex col-sm" v-else>
                                    <div class="tab_inner_content_image">
                                        <img :src="card.villa[0].villa_type_master.villa_images[0]" />
                                    </div>
                                    <div class="tab_inner_content_date">
                                        <span>{{ card.date_format }}</span>
                                        <div v-for="villaData in card.villa" :key="villaData">
                                            <p>{{ villaData.first_name }} {{ villaData.middle_name }} {{ villaData.last_name }} [{{ villaData.mobile }}]</p>
                                            <p>{{ villaData.villa_type_master.villa_name }}</p>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === 'COMPLETED'">
                                                <div class="aview_bar mr-1 success">
                                                    <a>
                                                        Paid
                                                    </a>
                                                </div>
                                                <div class="aview_bar">
                                                    <a :href="'http://chart.apis.google.com/chart?cht=qr&chs=250x250&chld=H|0&chl=' + villaData.user.id + '-' + villaData.user.mobile + '-' + villaData.user.user_type + '&choe=UTF-8'"
                                                        data-lightbox="models" data-title="STRUCTURE-2">
                                                        My QRcode
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '2'">
                                                <div class="aview_bar mr-1 warning">
                                                    <a v-if="card.order" :href="card.order.pay_link_web">
                                                        Pay Now
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="tab_inner_content_add_user" v-if="card.order && card.order.status === '3'">
                                                <div class="aview_bar mr-1 danger">
                                                    <a>
                                                        Cancelled
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- end dashboard first section -->
                    <!-- calander two section -->
                    <li data-step="bk_2" style="display:none;" >
                        <div class="v_bk_cat">
                            <div class="v_bk_cat_two">
                                <div class="calendar">
                                    <div class="group_month">
                                        <p class="left pointer minusmonth"><i class="fa fa-angle-left"
                                                aria-hidden="true"></i>
                                        </p>
                                        <p class="left monthname center pointer"></p>
                                        <p class="right pointer addmonth"><i class="fa fa-angle-right"
                                                aria-hidden="true"></i>
                                        </p>
                                    </div>
                                    <ul class="group">
                                        <li>Mo</li>
                                        <li>Tu</li>
                                        <li>We</li>
                                        <li>Th</li>
                                        <li>Fr</li>
                                        <li>Sa</li>
                                        <li>Su</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- endcalander two section -->
                    <!--  book villa three section -->
                    <li data-step="bk_3" style="display:none;">
                        <div class="bk_title">
                            Your Bookings
                        </div>
                        <div class="bk_title_main">
                            Accommodation Fit For ____ Profile
                        </div>
                        <div class="bk_title_info_info_main_row">
                            <div class="bk_title_info_info">
                                <div class="bk_title_info_info_inr">
                                    <div class="bk_title_info">
                                        <div class="bk_title_img">
                                            <img :src="bk_title_main" />
                                            <span>View Vila</span>
                                        </div>
                                        <div class="bk_content">
                                            <div class="vila_title">
                                                Platinum Villa
                                            </div>
                                            <div class="vila_title_info">
                                                <span> Max Quantity per</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>01 x</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>16</span>
                                            </div>
                                            <div class="main_qun">
                                                <ul class="main_qun_ul">
                                                    <li class="main_qun_li">
                                                        <img :src="first_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="second_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="three_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="four_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="five_img" alt="" class="main_qun_img">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vialla_qty_main_inner">
                                        <div class="vialla_qty_main">
                                            <span>Villa Qty</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="vialla_qty_main">
                                            <span>Total Prax</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bk_title_info_info">
                                <div class="bk_title_info_info_inr">
                                    <div class="bk_title_info">
                                        <div class="bk_title_img">
                                            <img :src="bk_title_main" />
                                            <span>View Vila</span>
                                        </div>
                                        <div class="bk_content">
                                            <div class="vila_title">
                                                Platinum Villa
                                            </div>
                                            <div class="vila_title_info">
                                                <span> Max Quantity per</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>01 x</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>16</span>
                                            </div>
                                            <div class="main_qun">
                                                <ul class="main_qun_ul">
                                                    <li class="main_qun_li">
                                                        <img :src="first_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="second_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="three_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="four_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="five_img" alt="" class="main_qun_img">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vialla_qty_main_inner">
                                        <div class="vialla_qty_main">
                                            <span>Villa Qty</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="vialla_qty_main">
                                            <span>Total Prax</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bk_title_info_info">
                                <div class="bk_title_info_info_inr">
                                    <div class="bk_title_info">
                                        <div class="bk_title_img">
                                            <img :src="bk_title_main" />
                                            <span>View Vila</span>
                                        </div>
                                        <div class="bk_content">
                                            <div class="vila_title">
                                                Platinum Villa
                                            </div>
                                            <div class="vila_title_info">
                                                <span> Max Quantity per</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>01 x</span>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                        fill="#153936" />
                                                </svg>
                                                <span>16</span>
                                            </div>
                                            <div class="main_qun">
                                                <ul class="main_qun_ul">
                                                    <li class="main_qun_li">
                                                        <img :src="first_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="second_img" alt="" class="main_qun_img">
                                                        <span>3</span>
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="three_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="four_img" alt="" class="main_qun_img">
                                                    </li>
                                                    <li class="main_qun_li">
                                                        <img :src="five_img" alt="" class="main_qun_img">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vialla_qty_main_inner">
                                        <div class="vialla_qty_main">
                                            <span>Villa Qty</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="vialla_qty_main">
                                            <span>Total Prax</span>
                                            <div class="main_class_inner_sd select_image">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                                        fill="#153936" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                                        fill="#153936" />
                                                </svg>
                                                <select>
                                                    <option value="volvo">1</option>
                                                    <option value="saab">2</option>
                                                    <option value="mercedes">3</option>
                                                    <option value="audi">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- end book villa three section -->
                    <!-- primary member detail four section -->
                    <li data-step="bk_4" style="display:none;">
                        <div class="pm_detail_block">
                            <div class="pm_detail_title">
                                <span>Primary Member Details</span>
                            </div>
                            <div class="pm_detail_box">
                                <form>
                                    <div class="pm_detail_col">
                                        <div class="pm_detail_12">
                                            <div class="pm_detail_lt">
                                                <div class="pm_deatil_icon">
                                                    <img :src="user_icon">
                                                </div>
                                            </div>
                                            <div class="pm_detail_rt">
                                                <div class="pm_deatil_in">
                                                    <label class="pm_label">First Name</label>
                                                    <input type="text" name="fname" class="pm_input">
                                                </div>
                                                <div class="pm_deatil_in">
                                                    <label class="pm_label">Middle Name</label>
                                                    <input type="text" name="fname" class="pm_input">
                                                </div>
                                                <div class="pm_deatil_in">
                                                    <label class="pm_label">Last Name</label>
                                                    <input type="text" name="fname" class="pm_input">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pm_detail_12">
                                            <div class="pm_detail_lt">
                                                <div class="pm_deatil_icon">
                                                    <img :src="call_icon">
                                                </div>
                                            </div>
                                            <div class="pm_detail_rt">
                                                <div class="pm_deatil_in">
                                                    <label class="pm_label">Phone Number</label>
                                                    <input type="number" name="fname" class="pm_input">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pnm_detail_butn">
                                            <button type="submit" class="pm_butn">
                                                Add Documents
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="mbr_detail_col">
                            <div class="mbr_detail_col_inr">
                                <div class="pm_detail_title">
                                    <span>Member Details</span>
                                </div>
                                <div class="mbr_detail_block">
                                    <ul class="mbr_detail_ul">
                                        <li class="mbr_detail_li">
                                            <div class="mbr_detail_div">
                                                <div class="pm_deatil_icon">
                                                    <img :src="user_icon">
                                                </div>
                                                <div class="mbr_detail_name">
                                                    <span>John 2</span>
                                                </div>
                                                <div class="mbr_detail_action">
                                                    <div class="mbr_del_icon mbr_dtl_img">
                                                        <img :src="delete_icon">
                                                    </div>
                                                    <div class="mbr_edit_icon mbr_dtl_img">
                                                        <img :src="edit_icon">
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="mbr_add_button">
                            <button type="button" class="mbr_add_btn">
                                <img :src="plus_icon">
                                <span>Add Member</span>
                            </button>
                        </div>
                    </li>
                    <!-- end primary member detail four section-->
                    <!-- check in/out five section -->
                    <li class="bk_5" style="display:none;">
                        <div class="check_block">
                            <div class="check_left">
                                <div class="check_inr_left">
                                    <div class="check_in_out_box">
                                        <div class="check_in_box">
                                            <div class="check_in_title">
                                                <span>Check-in</span>
                                            </div>
                                            <div class="check_in_text">
                                                <span>Wed, 1 Feb 2023</span>
                                            </div>
                                        </div>
                                        <div class="check_in_box">
                                            <div class="check_in_title">
                                                <span>Check-out</span>
                                            </div>
                                            <div class="check_in_text">
                                                <span>Wed, 1 Feb 2023</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="check_in_out_box check_in_out_box_bottom">
                                        <div class="check_in_box">
                                            <div class="check_in_title">
                                                <span>You selected</span>
                                            </div>
                                            <div class="check_in_text check_in_select">
                                                <div class="vila_title_info font_15">
                                                    <span> Max Quantity per</span>
                                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                            fill="#153936" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                            fill="#153936" />
                                                    </svg>
                                                    <span>01 x</span>
                                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                            fill="#153936" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                            fill="#153936" />
                                                    </svg>
                                                    <span>16</span>
                                                </div>
                                                <div class="vila_title_info font_15">
                                                    <span>Diamond Villa</span>
                                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                            fill="#153936" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                            fill="#153936" />
                                                    </svg>
                                                    <span>01 x</span>
                                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                            fill="#153936" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                            fill="#153936" />
                                                    </svg>
                                                    <span>16</span>
                                                </div>
                                                <div class="vila_title_info font_15">
                                                    <span>Lunch</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                        viewBox="0 0 15 15" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M1.25 5C1.25 3.27411 2.64911 1.875 4.375 1.875H10.625C12.3509 1.875 13.75 3.27411 13.75 5V7.5C13.75 7.84518 13.4702 8.125 13.125 8.125C12.7798 8.125 12.5 7.84518 12.5 7.5V5C12.5 3.96447 11.6605 3.125 10.625 3.125H4.375C3.33947 3.125 2.5 3.96447 2.5 5V10.625C2.5 11.6605 3.33947 12.5 4.375 12.5H7.5C7.84518 12.5 8.125 12.7798 8.125 13.125C8.125 13.4702 7.84518 13.75 7.5 13.75H4.375C2.64911 13.75 1.25 12.3509 1.25 10.625V5Z"
                                                            fill="black" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M5 1.25C5.34518 1.25 5.625 1.52982 5.625 1.875V3.75C5.625 4.09518 5.34518 4.375 5 4.375C4.65482 4.375 4.375 4.09518 4.375 3.75V1.875C4.375 1.52982 4.65482 1.25 5 1.25Z"
                                                            fill="black" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.75 6.25C3.75 5.90482 4.02982 5.625 4.375 5.625H10.625C10.9702 5.625 11.25 5.90482 11.25 6.25C11.25 6.59518 10.9702 6.875 10.625 6.875H4.375C4.02982 6.875 3.75 6.59518 3.75 6.25Z"
                                                            fill="black" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M10 1.25C10.3452 1.25 10.625 1.52982 10.625 1.875V3.75C10.625 4.09518 10.3452 4.375 10 4.375C9.65482 4.375 9.375 4.09518 9.375 3.75V1.875C9.375 1.52982 9.65482 1.25 10 1.25Z"
                                                            fill="black" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M11.25 10C10.5596 10 10 10.5596 10 11.25C10 11.9404 10.5596 12.5 11.25 12.5C11.9404 12.5 12.5 11.9404 12.5 11.25C12.5 10.5596 11.9404 10 11.25 10ZM8.75 11.25C8.75 9.86929 9.86929 8.75 11.25 8.75C12.6307 8.75 13.75 9.86929 13.75 11.25C13.75 12.6307 12.6307 13.75 11.25 13.75C9.86929 13.75 8.75 12.6307 8.75 11.25Z"
                                                            fill="black" />
                                                    </svg>
                                                    <span>03 Day</span>
                                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                            fill="#153936" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                            fill="#153936" />
                                                    </svg>
                                                    <span>16</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="check_right">
                                <div class="check_inr_right">
                                    <ul class="check_rt_ul">
                                        <li class="check_rt_li">
                                            <div class="check_rt_div">
                                                <div class="check_text">
                                                    <span>Total Villa Price</span>
                                                </div>
                                                <div class="check_numver">
                                                    <span>10,000</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="check_rt_li">
                                            <div class="check_rt_div">
                                                <div class="check_text">
                                                    <span>Total Lunch Price </span>
                                                </div>
                                                <div class="check_numver">
                                                    <span>1,500</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="check_rt_li">
                                            <div class="check_rt_div">
                                                <div class="check_text">
                                                    <span>Sub Total</span>
                                                </div>
                                                <div class="check_numver">
                                                    <span>11,500</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="check_rt_li">
                                            <div class="check_rt_div">
                                                <div class="check_text">
                                                    <span>18% GST</span>
                                                </div>
                                                <div class="check_numver">
                                                    <span>-1,900</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="check_add_btn">
                                        <button type="submit" class="pm_butn mini_btn">
                                            Add A Promo Code
                                        </button>
                                    </div>
                                    <div class="check_rt_div total_price_col">
                                        <div class="check_rt_div">
                                            <div class="check_text">
                                                <span>Total</span>
                                            </div>
                                            <div class="check_numver">
                                                <p class="sale_price">Rs. 14,400</p>
                                                <p class="regu_price">Rs. 13,400</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- end check in/out five section -->
                </ul>
            </div>
            <div class="main_reserves_row" v-show="totalFooterShow">
                <div class="main_reserves_col">
                    <div class="main_reserves_col_inner">
                        <span>Rs. 9,240 </span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                fill="#153936" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                fill="#153936" />
                        </svg>
                        <span>20</span>
                        <span class="main_class_innercol_span">+Rs. 1,109 taxes and charges</span>
                    </div>
                </div>
                <div class="main_reserves_col">
                    <button href="" class="main_button_user"><span>Reserve</span></button>
                </div>
            </div>
        </div>
        <div id="Restaurant" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2024
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="ClubHouse" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2025
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Movie" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2026
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Spa" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2027
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="RioStore" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2028
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Cleaning" class="w3-container w3-border city" style="display:none">
            <div class="tab_inner_content" style="background:url(../../assets/images/User/banner02.jpg);">
                <div class="tab_inner_content_main">
                    <div class="tab_inner_content_date">
                        25 Jan to 21 Jan 2029
                    </div>
                    <div class="tab_inner_content_add_user">
                        <div class="aview_bar">
                            <a href="#">View Barcode</a>
                        </div>
                        <div class="add_img">
                            <a href="#"><img :src="user_1">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MasterLayout>
</template>

<script>
import MasterLayout from './MasterLayout.vue';
import frame from '../../assets/images/User/Frame.png';
import bk_title_main from '../../assets/images/User/bk_title_main.png';
import call_icon from '../../assets/images/User/call_icon.png';
import user_icon from '../../assets/images/User/user-icon.png';
import plus_icon from '../../assets/images/User/plus-icon.png';
import delete_icon from '../../assets/images/User/delete-icon.png';
import edit_icon from '../../assets/images/User/edit-icon.png';
import first_img from '../../assets/images/User/first_img.png';
import second_img from '../../assets/images/User/second_img.png';
import three_img from '../../assets/images/User/three_img.png';
import four_img from '../../assets/images/User/four_img.png';
import five_img from '../../assets/images/User/five_img.png';
import user_1 from '../../assets/images/User/user_1.png';
import axios from "axios";

export default {
    name: 'MyBooking',
    components: { MasterLayout },
    data() {
        return {
            main_name: 'MyBooking',
            link: [
                { 'link': 'home', 'name': 'Home' },
            ],
            frame: frame,
            bk_title_main: bk_title_main,
            call_icon: call_icon,
            user_icon: user_icon,
            plus_icon: plus_icon,
            delete_icon: delete_icon,
            edit_icon: edit_icon,
            first_img: first_img,
            second_img: second_img,
            three_img: three_img,
            four_img: four_img,
            five_img: five_img,
            user_1: user_1,
            token: localStorage.getItem('auth_cus_token'),
            bookingList: [],
            totalFooterShow: false
        }
    },
    mounted() {
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        if (!this.token) {
            this.$router.push({ name: 'home' });
            window.location.href = "/";
        }
        this.getBookingHistory();

        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    methods: {
        async getBookingHistory() {
            this.loader = true;
            await axios.get(process.env.VUE_APP_API_CUS_URL + "/dashboard",
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': "application/json",
                        "Authorization": `Bearer ${this.token}`
                    }
                })
                .then((response) => {
                    this.bookingList = response.data.data;
                }).catch((e) => {
                    if (e.response.status == 401) {
                        localStorage.removeItem('auth_otp');
                        localStorage.removeItem('auth_user_type');
                        localStorage.removeItem('auth_cus_token');

                        this.removeLocalstorage();

                        this.$router.push({ name: 'home' });
                        window.location.href = "/";
                    }
                });
        },
        removeLocalstorage() {
            localStorage.removeItem('id');
            localStorage.removeItem('check_in_date');
            localStorage.removeItem('check_out_date');
            localStorage.removeItem('total_child');
            localStorage.removeItem('total_adult');
            localStorage.removeItem('qty');

            localStorage.removeItem('total');
            localStorage.removeItem('festival_charge');
            localStorage.removeItem('extra_person_charge');
            localStorage.removeItem('gst_tax');
            localStorage.removeItem('gst_tax_percent');
        }
    }
}
</script>

<style scoped>
.tab_inner_content_main {
    height: fit-content;
    margin: 10px;
    padding: 15px;
    display: inline-flex;
    width: 48%;
}

.tab_inner_content_image {
    width: 240px;
    height: 145px;
}

.tab_inner_content_image img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
}

.d_flex {
    display: flex;
}

.tab_inner_content_date {
    padding: 0 10px;
    font-size: 15px;
    width: 100%;
    text-align: left;
}

.tab_inner_content_add_user {
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-top: 0;
    padding: 0 10px 0 0px;
    background: transparent;
    font-size: 13px;
}
.aview_bar {
    padding: 8px;
}

.aview_bar.success {
    background-color: #5cb85c;
    border-color: #4cae4c;
    color: #fff;
    cursor: not-allowed;
}

.aview_bar.warning {
    background-color: #f0ad4e;
    color: #fff;
}

.aview_bar.danger {
    background-color: red;
    color: #fff;
}

.lightbox {
    top: 50% !important;
}

.mr-1 {
    margin-right: 10px;
}
</style>